@tailwind base;
@tailwind components;
@tailwind utilities;

/* @media (prefers-color-scheme: dark) { */
:root {
  --background: #171c28;
  --foreground: #e7e7e7;
}
/* } */

body {
  color: var(--foreground);
  background: var(--background);
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

@media print {
  * {
    color: var(--background);
    background: white;
  }
}
